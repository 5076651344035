.grid-box {
  width: 630px;

  mark {
    background-color: #fff1ba;
  }

  .grid-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: inherit;

    .grid-title {
      display: flex;
      font-weight: normal;
      font-style: normal;
      font-family: $primary-font;
      line-height: $line-height-sm;
      letter-spacing: $letter-spacing-sm;
    }

    .grid-actions {
      display: flex;
      justify-content: flex-end;

      .grid-add {
        margin-right: $space-md;
      }

      .grid-remove {
        margin-right: $space-hg;
      }

      .grid-search {
        display: flex;
        width: 174px;
      }
    }
  }

  .measurement-grid {
    width: inherit;
    height: 290px;
  }

  .digit-text-grid {
    width: inherit;
    height: 216px;
  }
}
