.m-0 {
  margin: 0 !important;
}

.ml-3 {
  margin-left: 3px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.mr-32 {
  margin-right: 32px !important;
}

.ml-14 {
  margin-left: 14px !important;
}

.ml-32 {
  margin-left: 32px !important;
}

.mr-12 {
  margin-right: 12px !important;
}

.pl-3 {
  padding-left: 3px !important;
}

.mr-24 {
  margin-right: 24px !important;
}

.mb-28 {
  margin-bottom: 24px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}
