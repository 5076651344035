@import 'variables';

$checkbox-width: 18px;
$checkbox-height: 18px;

.mat-checkbox {
  min-height: 32px;

  .mat-checkbox-layout {
    align-items: center;
  }

  .mat-checkbox-label {
    padding-left: 4px;
    font-size: $font-size-md;
    line-height: $line-height-md;
    letter-spacing: $letter-spacing-sm;
  }

  .mat-checkbox-frame {
    border-color: $neutral-medium;
  }

  .mat-checkbox-inner-container {
    width: $checkbox-width;
    height: $checkbox-height;
    margin: 7px;
  }

  .mat-checkbox-checkmark {
    padding-right: 0.5px;
    padding-left: 0.5px;
    opacity: 0;
  }

  .mat-checkbox-checkmark-path {
    stroke: $neutral-medium !important;
    stroke-dashoffset: 2.5 !important;
    stroke-width: 3px !important;
  }

  &:hover:not(.mat-checkbox-disabled) {
    .mat-checkbox-frame {
      border-color: $neutral-dark;
    }

    .mat-checkbox-checkmark-path {
      stroke: $neutral-dark !important;
    }
  }

  &.mat-checkbox-disabled {
    opacity: 0.4;
  }
}
