@import 'variables';

.info-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: $main-color-18;
  font-size: $font-size-sm;
  line-height: $line-height-lg;
  text-align: center;
}
