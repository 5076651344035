@import 'variables';

.cdk-overlay-pane {
  .mat-dialog-container {
    padding: 0;
    box-shadow: 0 5px 22px rgba(0, 46, 102, 0.12), 0 7px 8px rgba(0, 46, 102, 0.2);
  }

  .max-width-500 {
    .modal {
      max-width: 500px;
    }
  }
}

.modal {
  &-body {
    > form {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
      width: 100%;
      margin: auto;
    }
  }

  &-content {
    flex-grow: 1;
    width: 100%;
    margin-top: $space-xl;
  }

  &-footer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: $space-hg;

    app-button + app-button {
      margin-left: $space-lg;
    }
  }

  &-small {
    min-width: 256px;
    min-height: 124px;

    .modal-footer {
      justify-content: center;
      margin-top: $space-hg;

      button {
        width: 80px;
      }
    }
  }

  &-medium {
    min-width: 350px;
    min-height: 184px;
  }

  &-large {
    min-width: 504px;
    min-height: 260px;
    padding-bottom: 40px;

    > form {
      min-height: 196px;
    }
  }

  &-xlarge {
    min-width: 880px;
    min-height: 500px;
    padding-bottom: 40px;
  }

  &-title {
    color: $main-color-10;
    font-weight: $font-semi-bold;
    font-size: $font-size-md;
    letter-spacing: $letter-spacing-sm;
    text-align: center;
    word-break: break-word;

    &-sub {
      font-weight: normal;
      font-size: $font-size-sm;
      line-height: $line-height-lg;
    }
  }

  &-dimmer {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 900;
    background-color: #ffffff;
    opacity: 0.5;

    &.modal-visible {
      display: block;
    }
  }

  &-close-icon {
    position: absolute;
    top: $space-md;
    right: $space-md;
    color: $main-color-6;
    cursor: pointer;
  }
}

.modal-with-header {
  .modal-title {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    margin-bottom: $space-md;
    padding: $space-md;
    color: $text-base-75;
    font-weight: $font-regular;

    .vertical-line {
      width: 1px;
      height: 26px;
      margin-right: $space-lg;
      margin-left: $space-lg;
      background-color: $neutral-light;
    }

    .title-icon {
      margin-right: $space-lg;
      margin-left: $space-md;
    }
  }

  .modal-content {
    width: calc(100% - #{$space-md * 4});
    margin: 0 $space-md;
    padding: $space-md;
    background-color: #ffffff;
  }

  .modal-footer {
    width: calc(100% - 24px);
    margin: 0 $space-md;
    padding: 0;
    background-color: #ffffff;
  }

  .modal-close-icon {
    top: $space-sml;
    right: $space-md;
    color: $main-color-1;
  }
}

.change-lang-modal {
  .modal-body {
    padding-bottom: 40px;
  }

  .modal-content {
    margin-top: 52px;
  }
}
