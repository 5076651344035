@import 'variables';
@import 'mixins';

.input-group {
  position: relative;
  width: 100%;

  .prefix-icon + .input {
    padding-left: 36px;
  }

  .input:not(:last-child) {
    padding-right: 32px;
  }
}

.input {
  box-sizing: border-box;
  width: 100%;
  height: 32px;
  padding: 10px 16px;
  border: 1px solid $neutral-light;
  border-radius: 2px;
  color: $text-base;
  outline: none;
  font-weight: 400;
  font-size: $font-size-md;
  font-family: 'Roboto', serif;

  &:disabled {
    background-color: transparent;
    opacity: 0.5;
  }

  &:hover:not(:disabled) {
    border-color: $neutral-medium;
  }

  &:focus:not(:disabled),
  &:active:not(:disabled) {
    border-color: $neutral-medium;
  }

  &.invalid-input {
    border-color: $error-color-1 !important;
  }

  &.input-lg {
    height: 40px;
  }
}

.suffix-icon,
.prefix-icon {
  color: $text-base-50;
}

.prefix-icon {
  position: absolute;
  top: 6px;
  left: 8px;
}

.suffix-icon {
  position: absolute;
  top: 6px;
  right: 10px;
}

.input::placeholder {
  color: $text-base-50;
  font-weight: 400;
  font-size: $font-size-md;
  line-height: 20px;
}
