@import 'variables';

.mat-accordion {
  .mat-expansion-panel {
    border-radius: 2px;
    background: $main-color-14;

    &:not([class*='mat-elevation-z']) {
      box-shadow: 0 0 2px rgba(0, 46, 102, 0.15), 0 2px 2px rgba(0, 46, 102, 0.15), 0 1px 3px rgba(0, 0, 0, 0.2);
    }

    .mat-expansion-panel-header {
      height: 48px;
      padding: 0 $space-lg;

      .mat-expansion-panel-header-title {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-right: 0;
      }
    }
  }

  .mat-expansion-indicator {
    position: relative;
    width: 32px;
    height: 32px;
    border-radius: 2px;

    &::after {
      position: absolute;
      top: 10px;
      left: 11px;
      color: $text-base-50;
    }

    &:hover {
      background-color: $neutral-lightest;

      &::after {
        color: $neutral-dark;
      }
    }
  }
}
