// colors
$main-color-1: #f3f7fc;

// TODO: дубликат $primary-lightest;
$main-color-2: #e9f1fb;
$main-color-3: #d0e4f8;
$main-color-4: #cdddf0;
$main-color-5: #bfd2ea;
$main-color-6: #94adcc;
$main-color-7: #6487ad;
$main-color-8: #5b7b9e;
$main-color-9: #526f8e;
$main-color-10: #515d7a;
$main-color-11: #f5f6f7;
$main-color-12: #dadbdc;
$main-color-13: #666666;
$main-color-14: #ffffff;
$main-color-15: #000000;
$main-color-16: #bbd0eb;
$main-color-17: #dcdcdc;
$main-color-18: #809cbb;
$main-color-19: #d4deeb;
$main-color-20: #c9e0f7;
$main-color-21: #999999;
$main-color-22: #404040;
$main-color-23: #d3d7db;
$main-color-24: #7f8a96;
$main-color-25: #f6f6f6;
$main-color-26: #5076a5;
$main-color-27: #e8f0fe;
$main-color-28: #3f5d83;
$main-color-29: #6f90b9;
$main-color-30: #2c588f;

$accent-color-1: #f6b655;
$accent-color-2: #f7b050;
$accent-color-3: #f6aa55;
$accent-color-4: #fff1ba;

$warning-color: #a80000;
$error-color-1: #cc0000;
$error-light-color: #ffd7dd;
$error-color-2: #999999;
$salmon-pink: #ff94a4;
$error-highlight-color: #ffb6c1;
$error-light-color-2: #ffdadf;

$success-color-1: #c3e9a6;
$success-dark: #42731d;

$search-highlight-color: #fff1ba;

// margin, padding
$space-hg: 24px;
$space-xl: 20px;
$space-lg: 16px;
$space-xlg: 14px;
$space-md: 12px;
$space-sml: 10px;
$space-sm: 8px;
$space-xsm: 6px;
$space-xs: 4px;
$space-xxs: 2px;
$space-xxxs: 1px;

// border
$border-hg: 4px;
$border-lg: 2px;
$border-md: 1px;
$border-sm: 0.5px;

//font
$primary-font: 'Roboto', Arial, Helvetica, sans-serif;

// letter spacing
$letter-spacing-lg: 0.5px;
$letter-spacing-md: 0.25px;
$letter-spacing-sm: 0.15px;

// line height
$line-height-xlg: 36px;
$line-height-lg: 24px;
$line-height-md: 22px;
$line-height-sm: 20px;
$line-height-xs: 17px;
$line-height-xxs: 15px;
$line-height-xxxs: 10px;

// font-weight
$font-thin: 100;
$font-light: 300;
$font-regular: 400;
$font-semi-bold: 500;
$font-bold: 700;
$font-black: 900;

// font-size
$font-size-lg: 16px;
$font-size-md: 14px;
$font-size-sm: 12px;

//transitions
$transition-navigation: 0.4s;

// Spinner
$spinner-size-lg: 64px;
$spinner-size-md: 32px;
$spinner-size-sm: 26px;
$spinner-size-button: 20px;
$loader-font-size-lg: $font-size-lg;
$loader-font-size-md: $font-size-md;
$loader-font-size-sm: $font-size-sm;
$spinner-color: $main-color-9;

// box-shadow
$block-box-shadow: 0 3px 4px rgba(0, 46, 102, 0.12), 0 1px 5px rgba(0, 46, 102, 0.2);

// height
$button-default-height: 32px;
$input-icon-height: 20px;

// Disabled fields
$disabled-editing-opacity: 0.5;

// new color theme
$primary: #3d6ca6;
// TODO: дублирует $main-color-30
$primary-dark: #2c588f;
$primary-medium: #b6cce5;
$primary-light: #598ac8;
// TODO: дублирует $main-color-2
$primary-lightest: #e9f1fb;

$accent: #fab55b;
$accent-light: #fccb8c;
$accent-dark: #f89f2a;
$orange: #ff7d21;

$neutral-darkest: #22262a;
$neutral-dark: #505962;
$neutral-medium: #7e8c9a;
$neutral-light: #d1d8e0;
$neutral-lightest: #f5f7f9;
$neutral-grey: #eaeff4;

$semantic-info: #3782f2;
$semantic-info-light: #ecf4ff;
$semantic-warning: #f4c318;
$semantic-warning-light: #fef9e7;
$semantic-error: $error-color-1;
$semantic-error-light: #fff1f1;

$text-base: rgba(24, 26, 28);
$text-base-75: rgba(24, 26, 28, 0.75);
$text-base-50: rgba(24, 26, 28, 0.5);
$text-base-25: rgba(24, 26, 28, 0.25);
