@import 'mixins';

// Special style for loading-overlay-directive backdrop.
.app-loader-backdrop {
  margin: 0 !important;
  padding: 0 !important;
  border: 0 !important;
  background-color: rgba(255, 255, 255, 0.7) !important;
  user-select: none;
}

// TODO: Сделать компонент для оверлея по высоте и ширине с ng-content и флагом загрузки
// simple spinner container that expands its width and height to 100%
.spinner-container {
  @include flexify(row, center, center);
  width: 100%;
  height: 100%;
}
