@import 'variables';

html,
body {
  display: block;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-weight: $font-regular;
  font-size: $font-size-md;
  font-family: $primary-font;
  line-height: $line-height-sm;
}
