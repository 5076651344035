.d-flex {
  display: flex;
}

.ov-h {
  overflow: hidden;
}

.h-100pc {
  height: 100%;
}
