@import 'node_modules/@ag-grid-community/core/dist/styles/ag-grid';
@import 'node_modules/@ag-grid-community/core/dist/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin';
@import 'variables';

$tu-grid-row-height: 38px;
$settings-grid-row-height: 32px;

.ag-theme-tu {
  @include ag-theme-base(
    (
      'font-family': 'Roboto',
      'data-color': rgba(0, 0, 0, 0.87),
      'font-size': $font-size-md,
      'row-height': 56px,
      'selected-row-background-color': $main-color-2,
      'row-hover-color': $main-color-1,
      'border-color': $main-color-20,
      'row-group-indent-size': 21px,
      'range-selection-border-color': transparent,
    )
  );
}

.ag-theme-settings {
  @include ag-theme-base(
    (
      'font-family': 'Roboto',
      'font-size': $font-size-md,
      'row-height': 32px,
      'selected-row-background-color': $accent-color-4,
      'border-color': $neutral-light,
      'row-group-indent-size': 21px,
      'range-selection-border-color': $neutral-light,
      'input-focus-border-color': $neutral-light,
    )
  );
}

.ag-theme-tu,
.ag-theme-settings {
  letter-spacing: 0.25px;

  .ag-center-cols-viewport {
    overflow-x: hidden;
  }

  .ag-header-cell,
  .ag-header-group-cell {
    padding-right: 0;
    padding-left: 0;
  }

  // splitter with resize
  .ag-header-cell {
    overflow: visible;

    &:not(.splitter-none)::after {
      content: '';
      position: absolute;
      right: 0;
      width: 1px;
      height: 20px;
      background-color: $neutral-light;
    }
  }

  .ag-column-select-header {
    display: none;
  }

  .ag-column-select-list {
    padding: $space-md 14.5px;

    .ag-icon {
      display: none;
    }

    .ag-column-select-column-label {
      margin-left: $space-sm;
      color: $main-color-15;
      font-size: $font-size-sm;
      line-height: $line-height-lg;
      letter-spacing: $letter-spacing-sm;
    }
  }

  .ag-checkbox-input-wrapper {
    width: 15px;
    height: 15px;

    &::after {
      content: '' !important;
      width: 15px;
      height: 15px;
      background-color: $neutral-medium;
      mask-image: url('/assets/icons/w-checkbox.svg');
    }

    &:hover::after {
      background-color: $neutral-dark;
    }

    &.ag-checked::after {
      mask-image: url('/assets/icons/w-checkbox-checked.svg');
    }

    &.ag-indeterminate::after {
      mask-image: url('/assets/icons/w-checkbox-indeterminate.svg');
    }
  }

  .ag-root-wrapper {
    overflow: visible;
    border-radius: $border-lg;
  }

  .ag-row {
    border: 0 !important;
  }

  .ag-cell-focus {
    border-color: transparent;
  }

  .cell-rtl {
    direction: rtl;
  }

  .cell-d-flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: $tu-grid-row-height - 2px;
    padding-right: 0;
    padding-left: 0;
    border-width: 0 !important;
  }

  .segment-cell {
    padding-top: 16px;
    padding-bottom: 16px;
    line-height: 23px;
  }

  .cell-text-center {
    text-align: center;
  }

  .ag-side-buttons {
    width: 20px;
    padding-top: 0;
  }

  /* stylelint-disable selector-no-qualifying-type */
  button.ag-side-button-button {
    border-top-style: none;
    color: $main-color-10;
    font-size: $font-size-sm;
    line-height: $line-height-sm;
    letter-spacing: $letter-spacing-md;
  }

  /* stylelint-enable selector-no-qualifying-type */

  .ag-column-panel-column-select {
    border-top-style: none;
    border-bottom-style: none;
  }

  .checkbox-header {
    display: flex;
    align-content: center;
    justify-content: center;

    .ag-header-cell-comp-wrapper,
    app-grid-header {
      display: none;
    }

    .ag-header-select-all {
      margin-right: 0 !important;
    }
  }

  .checkbox-cell {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 100% !important;
    padding-right: 0 !important;
    padding-left: 0 !important;

    .ag-cell-wrapper {
      overflow: visible;
    }

    .ag-selection-checkbox {
      margin-right: 0 !important;
    }
  }
}

.ag-theme-tu {
  .ag-popup-child {
    border-width: 0;
  }

  .cell-d-flex-center {
    padding-top: 12px;
  }

  .ag-cell {
    padding-right: 0;
  }

  .cell-link-to-context-viewer:hover {
    color: $main-color-9;
    cursor: pointer;
    text-decoration-line: underline;
  }

  .cell-value-large {
    font-size: $font-size-lg;
  }

  .ag-center-cols-clipper {
    padding-left: 15px;
  }

  .ag-row {
    border-radius: $border-lg;
  }

  .ag-header-row {
    margin-left: 8px;
  }

  .ag-icon {
    mask-repeat: no-repeat;
  }

  .ag-icon-columns {
    width: 16px;
    height: 14px;
    background-color: $main-color-6;
    mask-image: url('/assets/icons/columns.svg');

    &::before {
      content: '';
    }
  }

  .ag-cell-inline-editing {
    height: auto;
    min-height: 100%;
    padding: 1px !important;
    border-width: 0 !important;
    border-radius: $border-lg;

    .ag-cell-wrapper {
      height: calc(100% - 2px);
    }
  }

  .ag-cell--source-segment {
    padding-right: 16px;
  }

  .ag-cell--target-segment {
    padding-left: 16px;

    .ag-cell-inline-editing {
      padding-left: 16px;
      background-color: $main-color-14;
      box-shadow: inset 0 0 0 1px #bfd2ea;
    }
  }

  .ag-group-value {
    font-size: $font-size-md;
    line-height: $line-height-lg;
    letter-spacing: $letter-spacing-sm;
  }

  .ag-root-wrapper {
    border-style: none;

    .ag-row-group {
      border-radius: $border-lg;
      background-color: $neutral-lightest;

      .ag-cell-wrapper {
        border: 0 !important;
      }
      /* stylelint-disable max-nesting-depth */
      &.ag-row-selected {
        .ag-cell-wrapper {
          box-shadow: inset 0 0 0 $border-md $main-color-12;
        }
      }

      &.ag-row-selected:not(&:hover) {
        .ag-cell-wrapper {
          box-shadow: inset 0 0 0 $border-md $main-color-5;
        }
      }
      /* stylelint-enable max-nesting-depth */
    }
  }

  .row-protected {
    .ag-cell-value {
      opacity: $disabled-editing-opacity;

      &.ignored-cell,
      &.report-protected-cell {
        opacity: 1;
      }
    }
  }

  .ag-header {
    margin-bottom: 3px;
    z-index: 1;
    border-color: $neutral-light;
  }

  .ag-cell .ag-checkbox {
    visibility: hidden;
  }

  .ag-row {
    &.ag-row-selected,
    &.ag-row-hover {
      .ag-cell .ag-checkbox {
        visibility: visible;
      }
    }

    &.ag-row-selected {
      box-shadow: inset 0 0 0 $border-md $main-color-5;
    }

    &.ag-row-hover.ag-row-selected {
      background-color: $main-color-3;
    }

    &.ag-row-group.ag-row-hover {
      box-shadow: inset 0 0 0 $border-md $main-color-12;
    }
  }

  .ag-ltr .ag-side-bar-right {
    border-left-color: $neutral-light;
  }

  /* stylelint-disable selector-no-qualifying-type */
  button.ag-side-button-button {
    border-bottom-color: $main-color-2;
  }

  /* stylelint-enable selector-no-qualifying-type */

  .ag-ltr .ag-side-bar-right .ag-tool-panel-wrapper {
    border-right-color: $main-color-2;
  }

  .grid-status-error,
  .grid-status-no-error {
    position: relative;
    width: 8px;
    height: 32px;

    &::after {
      content: '';
      position: absolute;
      top: calc(50% - 4px);
      left: 0;
      width: 8px;
      height: 8px;
      border-radius: $border-md;
      background-color: $error-color-2;
    }
  }

  .grid-status-error::after {
    background: $error-color-1;
  }

  .ag-icon-tree-closed {
    mask-image: url('/assets/icons/w-tree-closed.svg');
  }

  .ag-icon-tree-open {
    mask-image: url('/assets/icons/w-tree-open.svg');
  }

  .ag-icon-tree-closed,
  .ag-icon-tree-open {
    width: 12px;
    height: 8px;
    background-color: $text-base-50;
  }

  .ag-icon-tree-closed::before,
  .ag-icon-tree-open::before {
    content: '';
  }

  .ag-overlay-no-rows-wrapper {
    height: calc(100% - 33px);
    margin-top: 33px;
    background-color: $main-color-14;
  }

  &.search-grid,
  &.report-grid {
    .ag-body-horizontal-scroll-viewport {
      overflow-x: auto;
    }

    .ag-overlay {
      background: $main-color-14;
      z-index: 5;
      height: calc(100% - 33px);
      margin-top: 33px;
    }

    .ag-overlay-wrapper {
      margin-top: 0;
    }
  }

  .ag-filter-body-wrapper {
    /* stylelint-disable selector-no-qualifying-type */
    .ag-text-field-input,
    input[class^='ag-'][type='text'] {
      position: relative;
      width: 100%;
      padding-left: 28px;
      border: 1px solid $main-color-20;
      color: $main-color-15;
      font-size: $font-size-md;
      font-family: $primary-font;
      line-height: $line-height-sm;
      letter-spacing: inherit;

      &:focus,
      &:hover {
        border-color: $main-color-6;
      }
    }

    /* stylelint-enable selector-no-qualifying-type */
  }
}

.ag-theme-settings {
  .ag-row-hover .ag-cell:not(.checkbox-cell):hover {
    border-color: $neutral-light;
  }

  .ag-popup-editor {
    border-width: 0;
  }

  .ag-cell.ag-cell-popup-editing {
    border-color: $neutral-light;
  }

  .cell-d-flex-center {
    height: $settings-grid-row-height - 2px;
  }

  .checkbox-cell {
    height: $settings-grid-row-height - 2px;
    border-color: transparent !important;
  }

  .ag-cell-wrap-text {
    padding-top: 5px;
    padding-bottom: 5px;
    line-height: 20px;
  }

  .ag-text-field-input-wrapper {
    padding: 0 9px;
  }

  .ag-text-field-input {
    border: 0 !important;
  }
}

.ag-theme-settings .ag-cell-wrapper > app-simple-cell-editor {
  flex: 1 !important;
}
