@import 'variables';

mat-radio-button {
  color: $main-color-6;

  .mat-radio-inner-circle {
    background-color: $neutral-medium;
  }

  .mat-radio-outer-circle {
    border-color: $neutral-medium;
  }

  &:hover {
    .mat-radio-inner-circle {
      background-color: $neutral-dark;
    }

    .mat-radio-outer-circle {
      border-color: $neutral-dark;
    }
  }

  &.mat-radio-disabled,
  &:disabled {
    opacity: 0.3;
  }

  .mat-radio-label-content {
    color: $text-base;
    font-size: $font-size-md;
  }
}
