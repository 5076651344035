@import 'variables';

.mat-slide-toggle-label {
  .mat-slide-toggle-bar {
    margin-right: 16px;
    background-color: $main-color-17;

    .mat-slide-toggle-thumb {
      background-color: $main-color-14;
    }
  }

  .mat-slide-toggle-content {
    color: $text-base;
    font-size: $font-size-md;
  }
}

.mat-slide-toggle.mat-checked {
  .mat-slide-toggle-bar {
    background-color: $main-color-27;
  }

  .mat-slide-toggle-thumb {
    background-color: $primary;
  }
}
