@import 'variables';
@import 'mixins/index';

.context-menu {
  background: $main-color-14;
  border-radius: 2px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14), 0 3px 4px rgba(0, 0, 0, 0.12), 0 1px 5px rgba(0, 0, 0, 0.2);
  min-height: 32px;

  min-width: 200px;
  position: fixed;

  // NOTE: z-index should be bigger than modal z-index
  z-index: 1001;
}

.divider {
  background: $main-color-25;
  height: 1px;
  margin: 10px 0;
  width: 100%;
}

.context-menu-item {
  @include fontify(12, 400);

  cursor: pointer;
  padding: 6px 8px;

  &.disabled {
    @include font-opacity($main-color-15, 0.5);
    cursor: not-allowed;
  }
}
