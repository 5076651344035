* {
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-weight: 300;
  font-style: normal;
  font-family: 'Roboto';
  src: local('Roboto'), local('Roboto-Light'), url('/assets/fonts/Roboto-Light.woff2') format('woff2'),
    url('/assets/fonts/Roboto-Light.woff') format('woff');
  font-display: auto;
}

@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: 'Roboto';
  src: local('Roboto'), local('Roboto-Regular'), url('/assets/fonts/Roboto-Regular.woff2') format('woff2'),
    url('/assets/fonts/Roboto-Regular.woff') format('woff');
  font-display: auto;
}
@font-face {
  font-weight: 500;
  font-style: normal;
  font-family: 'Roboto';
  src: local('Roboto'), local('Roboto-Medium'), url('/assets/fonts/Roboto-Medium.woff2') format('woff2'),
    url('/assets/fonts/Roboto-Medium.woff') format('woff');
  font-display: auto;
}
