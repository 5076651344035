@import 'variables';

$tooltip-padding: 10px 16px;
$tooltip-border-radius: 2px;
$tooltip-z-index: 1000;
$tooltip-box-shadow: 0 4px 5px 0 rgba(0, 46, 102, 0.2), 0 3px 14px 0 rgba(0, 46, 102, 0.12);

$tooltip-font-family: $primary-font;
$tooltip-font-size: $font-size-sm;
$tooltip-font-weight: $font-regular;
$tooltip-line-height: 16px;

$tooltip-dark-background: $neutral-darkest;
$tooltip-dark-color: #ffffff;

$tooltip-light-background: #ffffff;
$tooltip-light-color: #000000;
$tooltip-light-border: 1px solid rgba(27, 31, 35, 0.15);
$tooltip-light-border-color: rgba(27, 31, 35, 0.15);

.tooltip-header {
  margin: 0 0 8px;
  font-weight: $font-semi-bold;
  font-size: $font-size-md;
}

@mixin tooltip-frame() {
  position: absolute;
  z-index: $tooltip-z-index;
  display: block;
  box-sizing: border-box;

  padding: $tooltip-padding;
  border-radius: $tooltip-border-radius;

  background-color: $tooltip-dark-background;
  color: $tooltip-dark-color;

  box-shadow: $tooltip-box-shadow !important;

  font-weight: $tooltip-font-weight;
  font-size: $tooltip-font-size;
  font-family: $tooltip-font-family;
  line-height: $tooltip-line-height;

  overflow-wrap: break-word;
  // Please, do not use `width: min-content;` here.
  //  Use it in place where you really need it and when you really need it.
}
