@import 'variables';
@import 'mixins';

$sub-level-margin: 28px;

.checkbox-group {
  .checkbox-group-item {
    min-height: 32px;
    margin-bottom: $space-xs;

    .mat-checkbox:last-child {
      margin: 2px 0;
    }

    .checkbox-group {
      margin-left: $sub-level-margin;
      padding-bottom: $space-xs;

      .checkbox-group-item {
        margin-bottom: 0;

        .mat-checkbox {
          margin: 0;
        }
      }
    }
  }
}
