@import 'variables';

.cdk-overlay-pane {
  background-color: transparent;

  .mat-snack-bar-container {
    min-width: auto;
    max-width: none;
    margin: 72px;
    padding: $space-md $space-hg !important;
    background: $main-color-22;
    color: $main-color-14;
    box-shadow: 0 2px 4px 0 #00000024;
    font-size: $font-size-md;
    line-height: $line-height-sm;
    letter-spacing: $letter-spacing-sm;

    .action-btn {
      margin-left: 16px;
    }
  }
}
