@import 'variables';

@mixin placeholder {
  color: $text-base-50;
  font-size: $font-size-md;
  font-weight: $font-regular;
}

@mixin colorfull-placeholder($color, $background: none, $width: auto) {
  &::placeholder {
    background: $background;
    color: $color;
    width: $width;
  }

  &::-webkit-input-placeholder {
    background: $background;
    color: $color;
    width: $width;
  }

  &:-moz-placeholder {
    background: $background;
    color: $color;
    width: $width;
  }

  &::-moz-placeholder {
    background: $background;
    color: $color;
    width: $width;
  }

  &:-ms-input-placeholder {
    background: $background;
    color: $color;
    width: $width;
  }
}
