.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.font-size-13 {
  font-size: 13px !important;
}

.font-size-12 {
  font-size: 12px !important;
}
